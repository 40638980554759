.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding: 20px;
}

table {
  border-collapse: collapse;
}

td {
  text-align: left;
}

th {
  color: #666;
}

td, th {
  border: 1px solid gray;
  padding: 5px 15px;
  height: 2em;
}

td.num, th.num {
  text-align: right;
}

.splitter {
  display: flex;
  align-items: stretch;
}

.splitter > * {
  margin-right: 20px;
}

.arrow {
  font-size: 50px;
  align-self: center;
}

.dummy {
  border: none;
}